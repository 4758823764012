import {ProductSection, PlantSection, PlanetSection, PeopleSection, DownloadSection, Footer} from './PlantSection.js';
import './landingpage.css';

function LandingPage() {
  return (
    <div className="Section">
      <ProductSection/>
      <PlantSection/>
      <PeopleSection/>
      <PlanetSection/>
      <DownloadSection/>
      <Footer/>
    </div>
  );
}

export default LandingPage;
