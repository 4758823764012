import React from 'react';
import { Footer } from './PlantSection';
import './privacy.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var terms = [{title: 'Eligibility', description: 'You must be at least 18 years old to use PlantSwap. By using the platform, you represent and warrant that you are of legal age and have the right to enter into this agreement.'},
            {title: 'User Accounts', description: 'To access certain features of PlantSwap, you may be required to create a user account. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities conducted under your account.'},
            {title: 'Plant Listings and Swaps', description: 'PlantSwap allows users to list plants available for swapping and communicate with other users for potential exchanges. By listing a plant for swapping, you affirm that you are the rightful owner of the plant and have the authority to trade it.'},
            {title: 'User Conduct', description: 'You agree to use PlantSwap for lawful purposes only and to abide by all applicable laws and regulations. You must not engage in any activities that may harm, disrupt, or compromise the integrity of PlantSwap or its users.'},
            {title: 'Prohibited Content', description: 'You must not post or exchange any content that is illegal, offensive, abusive, defamatory, discriminatory, or infringing on third-party rights. PlantSwap reserves the right to remove or block any content that violates these Terms.'},
            {title: 'Privacy', description: 'Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.'},
            {title: 'Intellectual Property', description: 'All content and materials on PlantSwap, including but not limited to logos, text, images, and software, are protected by copyright and other intellectual property laws. You may not use, modify, reproduce, distribute, or create derivative works based on PlantSwap\'s content without our explicit written permission.'},
            {title: 'Third-Party Links', description: 'PlantSwap may contain links to third-party websites or services. We are not responsible for the content or practices of these external sites. Your interactions with third-party websites are at your own risk, and you should review their terms and privacy policies before engaging with them.'},
            {title: 'Disclaimer of Warranties', description: 'PlantSwap is provided "as is" without warranties of any kind, whether express or implied. We do not guarantee the accuracy, reliability, or availability of the platform or its content. You use PlantSwap at your own risk.'},
            {title: 'Limitation of Liability', description: 'In no event shall PlantSwap or its affiliates be liable for any indirect, consequential, incidental, or special damages arising out of or in connection with the use of the platform or the inability to access it.'},
            {title: 'Indemnification', description: 'You agree to indemnify and hold PlantSwap, its officers, directors, employees, and agents harmless from any claims, damages, or liabilities arising from your use of the platform or any violation of these Terms.'},
            {title: 'Changes to the Terms', description: 'PlantSwap reserves the right to modify or update these Terms at any time. Any material changes will be communicated to you through prominent notices on the website or via email. Continued use of PlantSwap after such changes constitutes your acceptance of the revised Terms.'},
            {title: 'Termination', description: 'PlantSwap may terminate your access to the platform or suspend your account at any time and for any reason, without prior notice.'},
            {title: 'Governing Law', description: 'These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflicts of law principles.'},
             ];



const Terms = () => {
  return (
    <div className="privacy">
    <div className="section">
    <div className="Plant-details">
          <div className='Brand-section'>
          <Link className="footer-bold" to="/">
             <img className="Brand-icon" src={logo} alt='logo'></img>
          </Link>
            <div className="Brand-text">
            PLANT<br/>SWAP
            </div>
          </div>
    </div>
      <h1>Terms and Conditions for PlantSwap</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
      Welcome to PlantSwap! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using PlantSwap, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our platform.
      </p>
      <br/>
     
      <div>
      <ol>
      {terms.map((item)=>{
       return (<li><span className='title'>{item.title}</span><br/><p>{item.description}</p><br/></li>);
      })}
      </ol>
      </div>
    
   
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us at <a href='mailto:info@plantswap.io' className='link'> info@plantswap.io</a>
        </p>
        <p>
        Thank you for choosing PlantSwap! We are committed to creating a safe and vibrant community for plant lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Terms;
