import React from 'react';
import { Footer } from './PlantSection';
import './privacy.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var infoCollect = [{title: 'Personal Information', description: 'When you register an account with PlantSwap, we may collect personal information such as your name, email address, location, and profile picture. This information is used to create and manage your account, facilitate plant swaps, and enhance your overall experience on our platform.'},
            {title: 'Plant Listings', description: 'To facilitate plant exchanges, you may choose to provide details about the plants you wish to swap, such as plant names, descriptions, and images. This information is shared with other users to enable plant swapping.'},
            {title: 'Communication Data', description: 'We may collect data related to your communications with other users, customer support inquiries, and feedback. This information helps us address your queries and improve our services.'},
            {title: 'Usage Information', description: 'When you use PlantSwap, we collect usage data, such as your interactions with the platform, the types of plants you are interested in, and the frequency of your activities. This data is used to analyze user behavior, improve our platform, and enhance user satisfaction.'},
            {title: 'Log Data', description: 'Our servers automatically collect certain information when you access PlantSwap, including your IP address, browser type, operating system, and other technical details. This data is used for security purposes, analyzing trends, and ensuring the reliability and performance of our services.'},
            ];
var infoUse = [{title: 'Plant Swapping', description: 'Your personal and plant information is used to facilitate plant swaps between users. Your username and profile picture may be visible to other users during the swapping process.'},
            {title: 'Communication', description: 'We use your email address to communicate important updates, service-related information, and notifications about plant swap requests or responses.'},
            {title: 'Personalization', description: 'We analyze your usage and interaction data to personalize your experience on PlantSwap, providing tailored plant recommendations and improving our services.'},
            {title: 'Customer Support', description: 'Your information helps us respond to your inquiries, troubleshoot issues, and provide efficient customer support.'},
            {title: 'Security and Integrity', description: 'We utilize your data to ensure the security, integrity, and overall reliability of PlantSwap, preventing fraudulent activities and protecting both users and the platform.'},
            ];
var infoData = [{title: 'PlantSwap Community', description: 'Your username and profile picture, along with plant listing details, may be visible to other users within the PlantSwap community during the plant swapping process.'},
            {title: 'Service Providers', description: 'We may share your information with trusted third-party service providers who assist us in operating our platform, providing customer support, and analyzing user data. These providers are bound by confidentiality agreements and are only allowed to use your data for the purposes specified by us.'},
            {title: 'Legal Compliance', description: 'We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.'},
            ];
var infoPrivacy = [{title: 'Account Information', description: 'You have the right to review, update, or delete your personal information by accessing your account settings on PlantSwap.'},
                    {title: 'Communication Preferences', description: 'You can opt-out of receiving non-essential communications from us by adjusting your notification preferences in your account settings.'},
                   ];
var dataSecurity = [{title: 'Data Security:', description: 'We implement industry-standard security measures to protect your information from unauthorized access, loss, or misuse. However, please understand that no method of transmission over the internet or electronic storage is 100% secure.'},
                  ];
var changes = [{title: 'Changes to this Privacy Policy:', description: 'We may update this Privacy Policy from time to time. We will notify you of any material changes via email or through prominent notices on the PlantSwap website.'},
                ];


const PrivacyPolicy = () => {

  return (
    <div>
    <div className='privacy'>
    <div className="section">
    <div className="Plant-details">
          <div className='Brand-section'>
          <Link className="footer-bold" to="/">
             <img className="Brand-icon" src={logo} alt='logo'></img>
          </Link>
            <div className="Brand-text">
            PLANT<br/>SWAP
            </div>
          </div>
    </div>
      <h1>Privacy Policy for PlantSwap</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
        Welcome to PlantSwap! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard the data you provide to us when using our website and services. By accessing or using PlantSwap, you consent to the practices described in this policy.
      </p>
      <br/>
      <p className='title'>
      Information We Collect:
      </p>
      <div>
      <ol>
      {infoCollect.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}</li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      How We Use Your Information:
      </p>
      <div>
      <ol>
      {infoUse.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}</li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Data Sharing and Disclosure:
      </p>
      <div>
      <ol>
      {infoData.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}</li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Your Privacy Choices:
      </p>
      <div>
      <ol>
      {infoPrivacy.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}</li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Data Security:
      </p>
      <div>
        {dataSecurity[0].description}
      </div>
      <br/>
      <p className='title'>
      Changes to this Privacy Policy:
      </p>
      <div>
        {changes[0].description}
      </div>
      <br/>
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href='mailto:info@plantswap.io' className='link'> info@plantswap.io</a>
        </p>
        <p>
        Thank you for choosing PlantSwap! We are committed to creating a safe and vibrant community for plant lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
