
import {Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './privacy.js';
import Terms from './terms.js';
import Deletion from './delete.js';
import LandingPage from './landingpage.js';

function App() {
  return (
    <Routes>
       <Route exact path="/" element={<LandingPage/>} />
      <Route exact path="/privacy" element={<PrivacyPolicy/>} />
      <Route exact path="/terms" element={<Terms/>} />
      <Route exact path="/account-delete" element={<Deletion/>} />
   </Routes>
  );
}

export default App;
