import './PlantSection.css';
import logo from './logo.png';
import phone from './images/phone.png';
import applelogo from './images/appstore.png';
import googlelogo from './images/playstore.png';
import embraceSection from './images/embrace.png';
import exploreSection from './images/explore.png';
import nutureSection from './images/nuture.png';
import knowledgePeople from './images/knowledge-people.png';
import connectPeople from './images/connect-people.png';
import swapPeople from './images/swap-people.png';
import wastePlanet from './images/planet-waste.png';
import sustainabilityPlanet from './images/planet-sustainability.png';
import homePlanet from './images/planet-home.png';

import { Link, useLocation } from 'react-router-dom';

function plantHeader(color, name, description){
  
  return (
    <div>    
    <div className={`Section2-header ${color}`}>
    {name}
    </div>
    <div className={`Section2-body ${color}`}>
      {description}
     </div>
    </div>
  );
}

function plantItem(name, description){
  return (       
  <div className='Item-box'>
  <div className='Section2-sub-header dark'>
    {name}
  </div>
  <div className='Section2-body dark'>
    {description}
  </div>
 </div>);
}

function plantImage(src){
  return (       
    <div className='Item-box'>
    <img src={src} alt='title'></img>
   </div>
    );
}
function fadedPlantImage(src, title, description){
  return (
    <div>
    <div className='card'>
    <div className='overlay static'></div>
    <img className='static' src={src} alt='title'></img>
    <div className='card-body'>
      <div className='card-body-header'>
        {title}
      </div>
      <div className='card-body-text'>
        {description}
      </div>
    </div>
   </div>
    </div>
  );
}


export  function ProductSection() {
  return (
    <div className="Item-section-dark">
         <div className='Brand-section'>
              <img className="Brand-icon" src={logo} alt='logo'></img>
            <div className="Brand-text">
            PLANT<br/>SWAP
            </div>
          </div>

    <div  className="Item-section-dark" style={{paddingTop: "6px"}}>
      <div className='Item-left'>
        <div className="Plant-details">
          <div className="Section-header light">
          Where Plant Lovers Unite to Swap, Grow, and Flourish!
          </div>
          <div className="Section-body light">
          Connect, Exchange, and Swap Leafy Companions on a Joyful Botanical Quest!
          </div>
        </div>
      </div>
      <div className='Item-right' style={{paddingTop: "6px"}}>
          <img className="Plant-image" src={phone} alt='product'></img>
      </div>
    </div>
    </div>
  );
}

export function PlantSection() {
  return (
    <div className="Item-section-light">
         <div>
          {plantHeader('dark', 'PLANT', 'At PlantSwap, we celebrate the beauty and wonder of plants. Our platform is designed to help you explore, discover, and connect with a wide variety of plant species, fostering a sense of joy and inspiration in every interaction.')}
         </div>
      <div className='Item-collection'>
        <div> {plantImage(exploreSection)} </div>
        <div> {plantItem('Nurture and Grow', 'Unleash your green thumb and watch your plants flourish. PlantSwap is a platform where you can find valuable advice, tips, and tricks from experienced gardeners and fellow plant enthusiasts. Enhance your plant care skills and create thriving, healthy green spaces in your home or garden.')}</div>
        <div> {plantImage(embraceSection)} </div>
        <div> {plantItem('Explore a World of Plants', 'Immerse yourself in a world of botanical wonders. PlantSwap offers a diverse range of plants available for swapping, allowing you to expand your collection and discover new and exciting species. From lush tropicals to hardy succulents, there\'s something for every plant lover.')}</div>
        <div> {plantImage(nutureSection)} </div>
        <div> {plantItem('Embrace Plant Beauty', 'Experience the joy and tranquility that plants bring to our lives. PlantSwap is a place where you can connect with the inherent beauty of nature and surround yourself with living artwork. Discover the perfect plant companions that will add life, color, and serenity to your surroundings.')}</div>
      </div>
    </div>
  );
}

export function PeopleSection() {
  return (
    <div className="Item-section-dark">
        <div>
          {plantHeader('light', 'PEOPLE', 'At PlantSwap, we believe that plants have the power to bring people together and foster vibrant communities. Our platform is designed to connect passionate individuals who share a love for plants, enabling them to swap, share knowledge, and cultivate meaningful connections.')}
        </div>
        <div className='Item-collection'>
        <div> {fadedPlantImage(swapPeople, 'Swap Plants', 'Discover a diverse range of plants available for exchange on PlantSwap. From rare species to beloved favorites, our platform offers a vibrant marketplace where you can trade plants, expand your collection, and find new treasures to nurture in your own garden or home.')} </div>
        <div> {fadedPlantImage(knowledgePeople, 'Share Knowledge', 'Connect with experienced gardeners, enthusiastic plant lovers, and beginners alike. PlantSwap provides a space where you can freely share tips, tricks, and expertise, fostering an environment of learning and growth within the plant community. Expand your knowledge and help others along their plant journey.')} </div>
        <div> {fadedPlantImage(connectPeople, 'Connections', 'PlantSwap is more than just a platform for plant enthusiasts; it\'s a place to connect with like-minded individuals. Join a supportive community where you can build friendships, exchange stories, and celebrate the beauty of nature together. Our platform encourages connections that go beyond plants, creating lasting relationships centered around our shared love for greenery.')} </div>
         </div>
    </div>
  );
}

export function PlanetSection() {
  return (
    <div className="Item-section-light">
       <div>
          {plantHeader('dark', 'PLANET', 'At PlantSwap, we are committed to making a positive impact on our planet by reducing plant waste and promoting sustainable practices. By facilitating plant exchanges, we contribute to minimizing the environmental impact and fostering responsible plant ownership.')}
         </div>
      <div className='Item-collection'>
        <div> {plantImage(sustainabilityPlanet)} </div>
        <div> {plantItem('Promote Sustainability', 'We believe in building a greener future. By encouraging plant swaps and responsible plant ownership, PlantSwap promotes sustainability and raises awareness about the environmental benefits of nurturing and sharing plants. Join us in our mission to reduce waste and make a positive impact on our planet.')}</div>
        <div> {plantImage(wastePlanet)} </div>
        <div> {plantItem('Reduce Plant Waste', 'Help us combat plant waste by participating in the plant swapping culture. Instead of discarding unwanted plants, find them a new home where they will be appreciated and cared for. PlantSwap allows you to give your plants a second chance, ensuring they continue to thrive in the hands of passionate plant parents.')}</div>
        <div> {plantImage(homePlanet)} </div>
        <div> {plantItem('Find Forever Homes', 'PlantSwap offers a platform where you can find caring individuals who will provide forever homes for your plants. Whether you\'re downsizing, propagating, or simply looking to rehome a beloved plant, our community is here to ensure your plants are well taken care of and continue to thrive.')}</div>
      </div>
    </div>
  );
}

export function DownloadSection() {
  return (
    <div className="Item-section-dark">
        <div>
          {plantHeader('light', 'Download the App', 'So, join the plant revolution and let PlantSwap be your guide to a flourishing collection. Whether you\'re a seasoned plant parent or just starting your green journey, PlantSwap is here to help you find the perfect match and create a thriving ecosystem of plant love. Get ready to swipe, exchange, and witness the magic of plant connections like never before. Happy PlantSwapping!')}
        </div>
        <div className='download-layout'>
         <a href='https://apps.apple.com/gb/app/plantswap/id6448925017'><img src={applelogo} alt='apple'></img></a> 
           <a href='https://play.google.com/store/apps/details?id=com.plantmatch'><img src={googlelogo} alt='google'></img></a> 
        </div>
    </div>
  );
}

export function Footer() {
  const location = useLocation();
  function isCurrentPath(path){
    return location.pathname === path;
  }
  function handleScrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div className="Item-section-light">
        <div className='footer'>
          <div>
          <Link className={isCurrentPath('/privacy')?"footer-bold active": "footer-bold"} onClick={handleScrollToTop} to="/privacy">Privacy Policy</Link>
          <Link className={isCurrentPath('/terms')?"footer-bold active": "footer-bold"} onClick={handleScrollToTop} to="/terms">Terms & Conditions</Link>
          </div>
          <div className="footer-bold">
          © 2023 PlantSwap Technologies
          </div><br/>
        <div className="footer-text">
       <a href='mailTo:hello@plantswap.io'> hello@plantswap.io </a>
        </div><br/>
        <div className="footer-text">
        Bath court, Abdon Avenue, Birmingham, United Kingdom
            </div>
        </div>
    </div>
  );
}