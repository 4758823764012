import React from 'react';
import { Footer } from './PlantSection';
import './privacy.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var terms = [{title: 'Login to Your Account', description: 'Visit the PlantSwap website and log in using your credentials.'},
            {title: 'Access Account Settings', description: 'Once logged in, navigate to your account settings. This is usually found by clicking on your profile icon or username, and then selecting "Account Settings" or a similar option.'},
            {title: 'Locate Delete Account Option', description: 'Within the account settings, look for the "Delete Account" or "Close Account" option. The exact wording may vary, but it should be clear that it pertains to account deletion.'},
            {title: 'Initiate Deletion Process', description: 'Click on the "Delete Account" option, and you may be prompted to enter your password for verification purposes.'},
            {title: 'Confirmation', description: 'Upon confirmation, you may receive a pop-up to ensure that you genuinely want to proceed with the deletion.'},
            {title: 'Privacy', description: 'Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.'},
            {title: 'Review Data Deletion Policy', description: 'At this stage, you might be presented with a summary of the data that will be deleted and any information that may be retained for a specific period. Make sure to read this carefully to understand what data will be removed and what will be retained.'},
            ];



const Deletion = () => {
  return (
    <div className="privacy">
    <div className="section">
    <div className="Plant-details">
          <div className='Brand-section'>
          <Link className="footer-bold" to="/">
             <img className="Brand-icon" src={logo} alt='logo'></img>
          </Link>
            <div className="Brand-text">
            PLANT<br/>SWAP
            </div>
          </div>
    </div>
      <h1>Account Deletion for PlantSwap</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
      Welcome to PlantSwap! Requesting the Deletion of Your Account on PlantSwap:
      </p>
      <br/>
     
      <div>
      <ol>
      {terms.map((item)=>{
       return (<li><span className='title'>{item.title}</span><br/><p>{item.description}</p><br/></li>);
      })}
      </ol>

      <p className='title'>
      Types of Data Deleted or Kept:
      </p>
      <br/>
      <div>a. Deleted Data:</div>
      <ul>
        <li>Personal Information: Name, email address, phone number, physical address, etc.</li>
        <li>Account Information: Username, password, profile picture, and other account-related details.</li>
        <li>Listing Data: Any items or services you have posted for trade or sale.</li>
        <li>Direct Messages: Conversations with other users through the platform's messaging system.</li>
      </ul>
      <br/>
      <div>b. Retained Data:</div>
      <ul>
        <li>Anonymized Data: PlantSwap may retain anonymized data that does not personally identify you, which could be used for statistical or analytical purposes..</li>
        <li>Legal Obligations: PlantSwap might retain certain information required to comply with legal obligations, resolve disputes, or enforce agreements.</li>
      </ul>
      <div>
      <br/>
      <div>Additional Retention Period for PlantSwap:</div>
      <div>PlantSwap may specify an additional retention period for certain data based on its policies or legal requirements. For instance:</div>
      <ul>
        <li>Transaction Records: In some cases, records of completed transactions or interactions may be retained for accounting purposes or to address potential disputes.</li>
        <li>Security Logs: PlantSwap could retain security logs to monitor and prevent fraudulent activities.</li>
      </ul>
      <div>
      <br/>
      <div>It's essential to review our platform's <Link to="/privacy" className='link'>privacy policy </Link>
         or contact our customer support <a href='mailto:info@plantswap.io' className='link'> info@plantswap.io</a>, if you have specific concerns about data retention.</div>
   
    
      <p>Remember, always ensure that you genuinely want to delete your account as the process is often irreversible, and you may lose access to all data associated with your account once it's deleted.</p>
      <p>
        Thank you for choosing PlantSwap! We are committed to creating a safe and vibrant community for plant lovers while safeguarding your privacy.
      </p>
      </div>
      </div>
      </div>
      
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions or concerns, please contact us at <a href='mailto:info@plantswap.io' className='link'> info@plantswap.io</a>
        </p>
        <p>
        Thank you for choosing PlantSwap! We are committed to creating a safe and vibrant community for plant lovers while safeguarding your data.
        </p>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Deletion;